.header {
	margin-top: 35px;
	margin-bottom: 60px;

	h1 {
		$ratio: (419px / 80px);

		font: 0/0 none;
		padding-bottom: 100% / $ratio;
		background-image: url('./assets/logo.svg');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		@media (--desktop) {
			max-width: 480px;
			padding-bottom: 480px / $ratio;
		}
	}

	h2 {
		margin-top: 4px;
		font-size: 15px;
		font-weight: 700;
		text-transform: lowercase;

		@media (--desktop) {
			font-size: 22px;
		}
	}
}

.question {
	display: flex;
	gap: 24px;
	align-items: flex-start;
	margin-top: 24px;

	@media (--mobile) {
		flex-direction: column;
	}
}

.description {
	color: var(--color-accent);
	margin-right: auto;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;

	@media (--desktop) {
		max-width: 480px;
	}
}

.aside {
	flex-shrink: 0.25;
	border-radius: 7px;
	border: 3px solid var(--color-accent);

	@media (--mobile) {
		padding: 25px;
	}

	@media (--desktop) {
		width: 360px;
		padding: 30px;
		font-size: 16px;
	}

	p {
		font-weight: 600;

		&:empty {
			display: none;

			& + ul {
				margin-top: 0;
			}
		}
	}

	ul {
		font-weight: 400;
		margin-top: 0.5em;
		margin-left: 1.25em;
	}
}

.new-mobile {
	display: block;
	margin-top: 24px;
	padding-left: 20px;
	background-color: var(--color-accent);
	border-radius: 7px;
	color: #fff;
	font-size: 25px;
	font-weight: 700;
	line-height: 60px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	@media (--desktop) {
		display: none;
	}
}

.new-desktop {
	position: relative;
	height: 0;
	padding-bottom: calc(100% - 8px);
	border: 4px solid #000;
	border-radius: 7px;
	transition: 0.1s ease-out;
	transition-property: color, transform;

	&:only-child {
		width: 25%;
		padding-bottom: 25%;
	}

	&:hover {
		color: #fff;
		transform: translate(-4px, -4px);

		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;

		&::after {
			transform: translate(8px, 8px);
		}
	}

	span {
		position: absolute;
		right: 10%;
		bottom: 10%;
		left: 10%;
		font-size: 36px;
		font-weight: 700;
		line-height: 1.15;
	}

	@media (--mobile) {
		display: none;
	}
}

.controls {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 16px;
	margin-bottom: 40px;
}

.ideas {
	margin-bottom: 42px;

	@media (--desktop) {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
		gap: 24px 4.6%;
	}

	& > a,
	& > article {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			z-index: -1;
			width: 100%;
			padding-bottom: 100%;
			background-color: var(--color-accent);
			border-radius: 7px;
			transition: 0.1s ease-out;
			transition-property: transform, opacity;
		}

		&:hover::after {
			opacity: 1;
		}
	}

}
