:global .home-tags {

}

.label {
	display: block;
	background-color: transparent;
	padding-inline: 12px;
	border: 3px solid currentColor;
	border-radius: 7px;
	color: var(--color-accent);
	font-weight: 700;
	line-height: 38px;
	text-align: center;

	@media (--mobile) {
		&.open {
			background-color: var(--color-accent);
			color: #fff;

			&::before {
				filter: brightness(100);
			}

			& + .list {
				display: block;
			}
		}
	}

	@media (--desktop) {
		display: none;
	}
}

.list {
	list-style: none;
	margin-bottom: -12px;

	@media (--mobile) {
		display: none;
		margin-top: 14px;
	}

	li {
		display: inline-block;
		margin-bottom: 12px;
		margin-right: 10px;
	}

	a {
		display: block;
		padding-left: 15px;
		padding-right: 15px;
		border: 3px solid currentColor;
		border-radius: 7px;
		line-height: 30px;

		&:hover {
			color: var(--color-accent);
		}

		@media (--mobile) {
			color: var(--color-accent);

			&.active {
				background-color: var(--color-accent);
				border-color: var(--color-accent);
				color: #fff;
			}
		}

		@media (--desktop) {
			color: #000;

			&.active {
				color: var(--color-accent);
			}
		}
		
		&:not(.all) {
			text-transform: lowercase;

			&::before {
				content: '#';
			}
		}
	}
}
