.idea {
	position: relative;

	@media (--mobile) {
		& + & {
			margin-top: 40px;
		}
	}

	img {
		display: block;
		width: 100%;
		margin-bottom: 8px;
		border: 4px solid #000;
		border-radius: 7px;
		transition: transform 0.1s ease-out;
	}

	h1 {
		font-size: 22px;
		font-weight: 700;
		line-height: 1.4;

		// Clamp to 2 lines of text
		overflow: hidden;
		max-height: 1.4em * 2;
	}

	a:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&:hover {
		img {
			transform: translate(-4px, -4px);
		}

		&::after {
			transform: translate(4px, 4px);
		}
	}
}
