:global .home-sort {
	@media (--desktop) {
		position: absolute;
		left: 100%;
		margin-left: 42px;
		width: 60px;
		border: 3px solid #000;
		border-radius: 7px;

		&::before {
			content: attr(data-label);
			display: block;
			padding-top: 5px;
			padding-bottom: 5px;
			border-bottom: inherit;
			font-weight: 700;
			text-align: center;
		}
	}
}

.label,
.list a {
	@media (--mobile) {
		display: block;
		width: 100%;
	}
}

.label {
	background-color: transparent;
	padding-inline: 12px;
	border: 3px solid var(--color-accent);
	border-radius: 7px;
	color: var(--color-accent);
	font-weight: 700;
	line-height: 38px;
	text-align: left;

	@media (--mobile) {
		&::before {
			content: '';
			display: inline-block;
			width: 26px;
			height: 26px;
			margin-left: 4px;
			margin-right: 8px;
			vertical-align: -6px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 26px 26px;
			filter: var(--color-accent-filter);
		}

		&.open {
			background-color: var(--color-accent);
			color: #fff;

			&::before {
				filter: invert(1);
			}

			& + .list {
				display: block;
			}
		}
	}

	@media (--desktop) {
		display: none;
	}
}

.list {
	list-style: none;
	margin-top: 14px;

	@media (--mobile) {
		display: none;
	}

	@media (--desktop) {
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
	}

	li + li {
		@media (--mobile) {
			margin-top: 12px;
		}

		@media (--desktop) {
			margin-top: 20px;
		}
	}

	a {
		color: var(--color-accent);
		line-height: 30px;

		@media (--mobile) {
			border: 3px solid currentColor;
			border-radius: 7px;
		}

		@media (--desktop) {
			font: 0/0 none;
		}

		&::before {
			content: '';
			display: inline-block;
			width: 23px;
			height: 23px;
			vertical-align: -5px;
			background-position: center center;
			background-repeat: no-repeat;

			@media (--mobile) {
				margin-left: 28px;
				margin-right: 8px;
			}
		}

		&.active,
		&:hover {
			&::before {
				filter: var(--color-accent-filter);
			}
		}
	}
}

.latest::before {
	background-image: url('./assets/date.svg');
}

.popular::before {
	background-image: url('./assets/likes.svg');
}
